import { useState } from "react";

import { Box, FormControl, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

import { RuleFilterOptions } from "../../Pages/CloudAnalytics/allocations/types";
import { type AttributionFilter, type MetadataOption } from "../../types";
import { DimensionSelector } from "../Selects/CloudAnalytics/DimensionSelector";
import AllocationRuleChips from "./AllocationRuleChips";

const PROPERTY_FIELD_WIDTH = 250;

interface DimensionSelectorWithOperatorProps {
  handleSelectOption: (option: MetadataOption | null) => void;
  selectedDimension: MetadataOption | null;
  dimensions: MetadataOption[];
  disabled: boolean;
  onEditDimension: () => void;
  filter?: AttributionFilter;
  excludeSelectMetadataIds?: Set<string>;
  label?: string;
  handleChangeRule: (rule: RuleFilterOptions, filter: AttributionFilter, option: MetadataOption | null) => void;
  placeholder?: string;
}

const DimensionSelectorWithOperator = ({
  handleSelectOption,
  selectedDimension,
  dimensions,
  disabled,
  onEditDimension,
  placeholder,
  filter,
  excludeSelectMetadataIds,
  label,
  handleChangeRule,
}: DimensionSelectorWithOperatorProps) => {
  const [ruleFilter, setRuleFilter] = useState<RuleFilterOptions>(RuleFilterOptions.IS);

  const handleChange = (event: SelectChangeEvent<RuleFilterOptions>) => {
    setRuleFilter(event.target.value as RuleFilterOptions);
  };

  return (
    <Stack direction="row" spacing={1.25} alignContent="center">
      <Box sx={{ width: PROPERTY_FIELD_WIDTH }}>
        <DimensionSelector
          handleSelectOption={handleSelectOption}
          selectedDimension={selectedDimension}
          dimensions={dimensions}
          placeholder="select"
          disabled={disabled}
          onEdit={onEditDimension}
          excludeSelectMetadataIds={excludeSelectMetadataIds}
          textFieldProps={{
            variant: "outlined",
            margin: "dense",
            size: "small",
            sx: { width: { md: PROPERTY_FIELD_WIDTH } },
            label,
            placeholder,
          }}
        />
      </Box>
      {filter && (
        <>
          <FormControl size="small">
            <Select
              size="small"
              margin="dense"
              value={ruleFilter}
              onChange={handleChange}
              sx={{
                mb: 0.5,
                mt: 1,
              }}
            >
              {Object.values(RuleFilterOptions).map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => {
                    handleChangeRule(option, filter, selectedDimension);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <AllocationRuleChips filter={filter} nullFallback={null} title={selectedDimension?.data.label ?? ""} />
        </>
      )}
    </Stack>
  );
};

export default DimensionSelectorWithOperator;
